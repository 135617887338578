<template>
  <div class="wa__tail">
    <div class="wa__tail__tail">
      <div class="wa__tail__tail__main">
        <i :class="icon" class="icon"></i>
        <div class="wa__tail__tail__main__title">
          <div class="wa__tail__tail__main__title--title">
            <h3>{{ text }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {},
    icon: {},
  },
};
</script>
<style scoped>
.wa__tail {
  display: flex;
  justify-content: center;
}
.wa__tail__tail {
  text-align: center;
  background-color: var(--color-bg-gray);
  width: 100%;
  margin: 5px 0;
  position: relative;
  height: 55px;
  border-radius: 5px;
}
.wa__tail__tail__main {
  padding-top: 10px;
  padding-left: 20px;
  display: flex;
  align-items: baseline;
  text-align: left;
  justify-content: center;
}
.wa__tail__tail__main i {
  font-size: 20px;
}
@media screen and (max-width: 1264px) {
  .wa__tail__tail {
    height: 45px;
  }
  .wa__tail__tail__main__title--title h3 {
    font-size: 1.5rem;
  }
}
</style>