var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wa__container"},[_c('header',{staticClass:"pt-3"},[_c('div',{staticClass:"pt-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('site-header',{attrs:{"title":_vm.$_t('title'),"sub-title":_vm.$_t('subTitle'),"login":_vm.login}})],1)],1)],1)]),_c('main',{staticClass:"mb-3"},[_c('v-row',{staticClass:"mt-0"},[(_vm.userType === 'manager')?_vm._l((_vm.managerTiles),function(tile,i){return _c('v-col',{key:i,class:tile.classes},[_c('router-link',{attrs:{"to":{
              name: tile.routeName,
              params: { locale: _vm.$_getlocaleParam() },
            }}},[(!tile.hasSmallTile)?_c('tile',{staticClass:"animate__animated animate__fadeIn",attrs:{"small":tile.small,"title":tile.title,"sub-title":tile.subTitle,"notification-title":tile.notificationTitle,"notification-text":tile.notificationText,"icon":tile.icon,"color":tile.color,"bg-color":tile.bgColor}}):_c('small-tile',{attrs:{"text":tile.title,"icon":tile.icon}})],1)],1)}):(_vm.userType === 'model')?_vm._l((_vm.modelTiles),function(tile,i){return _c('v-col',{key:i,class:tile.classes},[_c('router-link',{attrs:{"to":{
              name: tile.routeName,
              params: { locale: _vm.$_getlocaleParam() },
            }}},[(!tile.hasSmallTile)?_c('tile',{staticClass:"animate__animated animate__fadeIn",attrs:{"small":tile.small,"title":tile.title,"sub-title":tile.subTitle,"notification-title":tile.notificationTitle,"notification-text":tile.notificationText,"icon":tile.icon,"color":tile.color,"bg-color":tile.bgColor}}):_c('small-tile',{attrs:{"text":tile.title,"icon":tile.icon}})],1)],1)}):_vm._l((_vm.clientTiles),function(tile,i){return _c('v-col',{key:i,class:tile.classes},[_c('router-link',{attrs:{"to":{
              name: tile.routeName,
              params: { locale: _vm.$_getlocaleParam() },
            }}},[(!tile.hasSmallTile)?_c('tile',{staticClass:"animate__animated animate__fadeIn",attrs:{"small":tile.small,"title":tile.title,"sub-title":tile.subTitle,"notification-title":tile.notificationTitle,"notification-text":tile.notificationText,"icon":tile.icon,"color":tile.color,"bg-color":tile.bgColor}}):_c('small-tile',{attrs:{"text":tile.title,"icon":tile.icon}})],1)],1)})],2)],1),_c('footer',[_c('div',{staticClass:"wa__home--footer"},[_c('site-footer',{attrs:{"text":_vm.$_t('footerText')}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }