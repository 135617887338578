<template>
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3">
        <v-row>
          <v-col cols="12">
            <site-header
              :title="$_t('title')"
              :sub-title="$_t('subTitle')"
              :login="login"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <main class="mb-3">
      <v-row class="mt-0">
        <template v-if="userType === 'manager'">
          <v-col :class="tile.classes" :key="i" v-for="(tile, i) in managerTiles">
            <router-link
              :to="{
                name: tile.routeName,
                params: { locale: $_getlocaleParam() },
              }"
            >
              <tile
                v-if="!tile.hasSmallTile"
                :small="tile.small"
                :title="tile.title"
                :sub-title="tile.subTitle"
                :notification-title="tile.notificationTitle"
                :notification-text="tile.notificationText"
                :icon="tile.icon"
                :color="tile.color"
                :bg-color="tile.bgColor"
                class="animate__animated animate__fadeIn"
              ></tile>
              <small-tile v-else :text="tile.title" :icon="tile.icon"></small-tile>
            </router-link>
          </v-col>
        </template>
        <template v-else-if="userType === 'model'">
          <v-col :class="tile.classes" :key="i" v-for="(tile, i) in modelTiles">
            <router-link
              :to="{
                name: tile.routeName,
                params: { locale: $_getlocaleParam() },
              }"
            >
              <tile
                v-if="!tile.hasSmallTile"
                :small="tile.small"
                :title="tile.title"
                :sub-title="tile.subTitle"
                :notification-title="tile.notificationTitle"
                :notification-text="tile.notificationText"
                :icon="tile.icon"
                :color="tile.color"
                :bg-color="tile.bgColor"
                class="animate__animated animate__fadeIn"
              ></tile>
              <small-tile v-else :text="tile.title" :icon="tile.icon"></small-tile>
            </router-link>
          </v-col>
        </template>
        <template v-else>
          <v-col :class="tile.classes" :key="i" v-for="(tile, i) in clientTiles">
            <router-link
              :to="{
                name: tile.routeName,
                params: { locale: $_getlocaleParam() },
              }"
            >
              <tile
                v-if="!tile.hasSmallTile"
                :small="tile.small"
                :title="tile.title"
                :sub-title="tile.subTitle"
                :notification-title="tile.notificationTitle"
                :notification-text="tile.notificationText"
                :icon="tile.icon"
                :color="tile.color"
                :bg-color="tile.bgColor"
                class="animate__animated animate__fadeIn"
              ></tile>
              <small-tile v-else :text="tile.title" :icon="tile.icon"></small-tile>
            </router-link>
          </v-col>
        </template>
      </v-row>
    </main>
    <footer>
      <div class="wa__home--footer">
        <site-footer :text="$_t('footerText')"></site-footer>
      </div>
    </footer>
  </div>
</template>
<script>
// import Tails from "../components/Global/Section/Tiles.vue";
import SmallTile from "../components/Global/Section/SmallTile.vue";
import { UserStorage } from "@/utils/storage";
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    // Tails,
    SmallTile,
  },
  computed: {
    ...mapGetters("user", ["getNotifications","getMyInfo"]),
    // manager tiles
    managerTiles() {
      let managerTiles = [
        {
          title: this.$_t("adminProjectTitle"),
          subTitle: this.$_t("adminProjectSubTitle"),
          icon: "WMi-chat-alt",
          routeName: "projects",
          classes: "wa__tile--link py-0 pr-md-0 pr-3 col-4",
          small: false,
        },
        {
          title: this.$_t("hiringRequestTitle"),
          subTitle: this.$_t("hiringRequestSubTitle"),
          icon: "WMi-chat-alt",
          routeName: "requests",
          classes: "wa__tile--link py-0 col-8",
          small: false,
        },
        {
          title: this.$_t("OfferTitle"),
          subTitle: this.$_t("OfferSubTitle"),
          icon: "WMi-ok",
          routeName: "offers",
          classes: "wa__tile--link py-0 col-6",
          small: true,
        },
        {
          title: this.$_t("EmailTitle"),
          subTitle: this.$_t("EmailSubTitle"),
          icon: "WMi-mail-alt",
          routeName: "emailManagment",
          classes: "wa__tile--link py-0 col-6 pl-0",
          small: true,
        },
        {
          title: this.$_t("ModelsTitle"),
          subTitle: this.$_t("ModelsSubTitle"),
          notificationTitle: this.getNotifications.models,
          notificationText: this.$_t("notificationModelText"),
          icon: "WMi-crown-4",
          routeName: "models",
          classes: "wa__tile--link py-0 col-12",
          small: false,
        },
        {
          title: this.$_t("managers"),
          icon: "WMi-users-3",
          routeName: "managers",
          classes: "wa__tile--link pb-0 pr-3 pr-md-0 pt-0 col-12 col-sm-2",
          small: false,
        },
        {
          title: this.$_t("settings"),
          icon: "WMi-glyph-27",
          routeName: "setting",
          classes: "wa__tile--link pb-0 pr-3 pr-md-0 pt-0 col-12 col-sm-2",
          small: false,
        },
        {
          title: this.$_t("brands"),
          icon: "WMi-bookmark",
          routeName: "brands",
          classes: "wa__tile--link pb-0 pr-3 pr-md-0 pt-0 col-12 col-sm-2",
          small: false,
        },
        {
          title: this.$_t("ClientsTitle"),
          subTitle: this.$_t("ClientsSubTitle"),
          icon: "WMi-heart",
          routeName: "clients",
          classes: "wa__tile--link py-0 col-12 col-sm-6",
          small: false,
        },
        {
          title: this.$_t("BookmarkTitle"),
          subTitle: this.$_t("BookmarkSubTitle"),
          icon: "WMi-bookmark",
          routeName: "bookmarks",
          classes: "wa__tile--link py-0 pr-3 pr-md-0 col-12 col-sm-3",
          small: true,
        },
        {
          title: this.$_t("PortfolioTitle"),
          subTitle: this.$_t("PortfolioSubTitle"),
          icon: "WMi-videocam",
          routeName: "portfolio",
          classes: "wa__tile--link py-0 pr-3 pr-md-0 col-12 col-sm-3",
          small: true,
        },
        {
          title: this.$_t("BlogTitle"),
          subTitle: this.$_t("BlogSubTitle"),
          icon: "WMi-rss",
          routeName: "blog",
          classes: "wa__tile--link py-0 pr-0 col-3",
          small: true,
        },
        {
          title: this.$_t("MessageTitle"),
          subTitle: this.$_t("MessageSubTitle"),
          icon: "WMi-envelope-open",
          routeName: "contactUs",
          classes: "wa__tile--link py-0 col-3",
          small: true,
        },
      ];
      return managerTiles;
    },
    // model tiles
    modelTiles() {
      let modelTiles = [
        // ! remove hiring request from model tiles
        // {
        //   title: this.$_t("projectTitle"),
        //   subTitle: this.$_t("projectSubTitle"),
        //   icon: "WMi-crown-4",
        //   routeName: "projects",
        //   classes: "wa__tile--link py-0 pr-3 pr-md-0 col-12 col-md-5",
        //   small: false,
        // },
        // {
        //   title: this.$_t("workOffersTitle"),
        //   subTitle: this.$_t("workOffersSubTitle"),
        //   notificationTitle: this.getNotifications.request,
        //   notificationText: this.$_t("notificationRequestText"),
        //   icon: "WMi-chat-alt",
        //   routeName: "requests",
        //   classes: "wa__tile--link py-0 col-12 col-md-7",
        //   roles: ["model"],
        //   small: false,
        // },
        {
          title: this.$_t("projectTitle"),
          subTitle: this.$_t("projectSubTitle"),
          icon: "WMi-crown-4",
          routeName: "projects",
          classes: "wa__tile--link py-0 pr-3 pr-md-0 col-12 col-md-5",
          small: false,
        },
        {
          title: this.$_t("BookmarkTitle"),
          subTitle: this.$_t("BookmarkSubTitle"),
          icon: "WMi-bookmark",
          routeName: "bookmarks",
          classes: "wa__tile--link py-0 col-12 col-md-7",
          small: false,
        },
        {
          title: this.$_t("ProfileTitle"),
          subTitle: this.$_t("ProfileSubTitle"),
          icon: "WMi-heart",
          routeName: "modelEditByModel",
          classes: "wa__tile--link py-0 pr-3 pr-md-0 col-12 col-md-7",
          small: false,
        },
        {
          title: this.$_t("galleryTitle"),
          subTitle: this.$_t("gallerySubTitle"),
          icon: "WMi-picture",
          routeName: "modelGallery",
          classes: "wa__tile--link pt-0 col-12 col-md-5",
          small: false,
        },
      ];
      return modelTiles;
    },
    // client tiles
    clientTiles() {
      let clientTiles = [
        {
          title: this.$_t("projectTitle"),
          subTitle: this.$_t("projectSubTitle"),
          icon: "WMi-crown-4",
          routeName: "projects",
          classes: "wa__tile--link pb-0 pr-3 pr-md-0 col-12 col-md-4",
          small: false,
        },
        {
          title: this.$_t("requestTitle"),
          subTitle: this.$_t("requestSubTitle"),
          notificationTitle: this.getNotifications.request,
          notificationText: this.$_t("notificationRequestText"),
          icon: "WMi-chat-alt",
          routeName: "requests",
          classes: "wa__tile--link pb-0 pt-0 pt-md-3 col-12 col-md-8",
          small: false,
        },
        {
          title: this.$_t("ProfileTitle"),
          subTitle: this.$_t("ProfileSubTitle"),
          icon: "WMi-heart",
          routeName: "profile",
          classes: "wa__tile--link pt-0 pb-0 pr-3 pr-md-0 col-12 col-md-6",
          small: false,
        },
        {
          title: this.$_t("BookmarkTitle"),
          subTitle: this.$_t("BookmarkSubTitle"),
          icon: "WMi-bookmark",
          routeName: "bookmarks",
          classes: "wa__tile--link pt-0 pb-0 pr-md-0 col-12 col-md-6",
          small: false,
        },
        {
          title: this.$_t("becomeAModelTitle"),
          icon: "WMi-plus",
          routeName: this.getMyInfo.model_id ? "requestSended" : "modelRegistration",
          classes: "wa__tile--link pt-0 col-12",
          small: false,
          hasSmallTile: true,
        },
      ];
      return clientTiles;
    },
  },
  data() {
    return {
      userType: UserStorage.get() ? UserStorage.get().type : "client",
      modelId: UserStorage.get().id,
      login: true,
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.wa__tile--link a {
  text-decoration: none;
  display: block;
  color: initial;
}

.pointer,
.wa__tile--link {
  cursor: pointer;
}
</style>
